










import {Component, Vue, Prop} from 'vue-property-decorator';
import ErrorMessage from './ErrorMessage.vue';

@Component({
    components: {ErrorMessage},
})
export default class Field extends Vue {
    @Prop() private readonly title!: string;
    @Prop() private readonly error!: string | void;
    @Prop() private readonly hasError!: boolean;
}
