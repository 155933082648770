















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PortalZone } from '../domain/PortalZone';
import { TransferData } from 'element-ui/types/transfer';
import Field from '@/components/Field.vue';

@Component({
    components: { Field },
})
export default class PortalZoneTransfer extends Vue {
    @Prop({default: []}) private readonly givenSelectedZones!: PortalZone[];
    @Prop() private readonly zones!: PortalZone[];
    @Prop() private readonly portalId!: string;

    private selectedZonesIds: string[] = [];
    private transferData1: TransferData[] = [];
    private allZones: PortalZone[] = [];

    private updateSelectedZones() {
        const zones = this.findZonesFromSelectedIds();
        this.selectedZonesIds = zones.map((zone) => zone!.id || '');
        this.$emit('updateZones', zones, this.portalId);
    }

    private findZonesFromSelectedIds() {
        return this.selectedZonesIds.map((zone) =>  this.allZones.find(((z) => z.id === zone)));
    }

    private formatZoneLabelFor(zone: PortalZone) {
        if (zone.height !== null && zone.width !== null) {
            return `${zone.name} (${zone.height}x${zone.width})`;
        }

        return zone.name;
    }

    @Watch('givenSelectedZones', { deep: true, immediate: true })
    private onGivenSelectedZonesUpdate() {
        this.selectedZonesIds = this.givenSelectedZones.map((zone) => zone.id || '');
    }

    @Watch('zones')
    private onZonesUpdate() {
        if (!this.givenSelectedZones) {
            this.selectedZonesIds = [];
        }
    }

    get transferData(): TransferData[] {
        this.transferData1 = this.zones.map((zone) => {
            this.allZones.push(zone);
            return {
                key: zone.id,
                label: this.formatZoneLabelFor(zone),
                disabled: false,
            };
        });

        this.givenSelectedZones.map((zone) => {
            if (!this.zones.some((e) => e.id === zone.id)) {
                this.transferData1.push({
                    key: zone.id,
                    label: this.formatZoneLabelFor(zone),
                    disabled: true,
                });

                this.allZones.push(zone);
            }
        });
        return this.transferData1;
    }
}
