






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ErrorMessage extends Vue {
    @Prop() private readonly text!: string;
    @Prop() private readonly isVisible!: boolean;
}
